.welcome-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  animation: fadeIn 0.5s ease-out;
}

.welcome-card {
  background-color: #1f2937;
  color: white;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  width: 90%;
  max-width: 700px;
  max-height: 90vh;
  overflow-y: auto;
  animation: slideUp 0.5s ease-out;
}

.welcome-card h1 {
  font-size: 2.5rem;
  margin: 0 0 20px 0;
  color: #3b82f6;
  text-align: center;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.welcome-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (min-width: 768px) {
  .welcome-content {
    flex-direction: row;
  }
}

.welcome-section {
  flex: 1;
  padding: 15px;
  background-color: #2d3748;
  border-radius: 8px;
}

.welcome-section h2 {
  font-size: 1.4rem;
  margin: 0 0 15px 0;
  color: #60a5fa;
  border-bottom: 1px solid #4b5563;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.welcome-section ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.welcome-section li {
  padding: 8px 0;
  border-bottom: 1px solid #374151;
  font-size: 1rem;
  line-height: 1.5;
}

.welcome-section li:last-child {
  border-bottom: none;
}

.welcome-section li strong {
  color: #93c5fd;
}

.welcome-footer {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.start-button {
  background-color: #2563eb;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 30px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.start-button:hover {
  background-color: #1d4ed8;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.start-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
} 