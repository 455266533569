.app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  /* Prevent text selection on touch devices */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Prevent iOS callout when touching and holding */
  -webkit-touch-callout: none;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  /* Prevent text selection on touch devices */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Prevent iOS callout when touching and holding */
  -webkit-touch-callout: none;
}

.game-container {
  width: 100%;
  height: 100%;
  position: relative;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Prevent default touch actions like pinch-zoom, double-tap zoom, etc. */
  touch-action: none;
}

/* Remove conflicting UI container styles from App.css since we're using UI.css */
/* .ui-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
} */

.toolbar {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 10px;
  pointer-events: auto;
}

.block-button {
  width: 50px;
  height: 50px;
  margin: 0 5px;
  border: 2px solid #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
}

.block-button.selected {
  border-color: #ffcc00;
  transform: scale(1.1);
}
