.block-selector-wrapper {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  z-index: 1000;
  pointer-events: auto;
  width: 80vw;
  max-width: calc(100vw - 40px);
  min-width: 320px;
  min-height: 160px;
  max-height: 160px;
}

.category-tabs {
  display: flex;
  flex-direction: row;
  background-color: rgba(40, 44, 52, 0.95);
  border-radius: 8px 8px 0 0;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  min-height: 40px;
  max-height: 60px;
}

.category-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 10px 15px;
  cursor: pointer;
  color: #ccc;
  font-weight: 500;
  transition: all 0.2s;
  border: none;
  background: transparent;
  white-space: nowrap;
  flex: 0 0 auto;
  min-width: 80px;
  position: relative;
}

.category-tab:hover {
  background-color: rgba(60, 64, 72, 0.95);
  color: white;
}

.category-tab.selected {
  background-color: rgba(80, 84, 92, 0.95);
  color: white;
}

.category-tab.selected::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 10%;
  width: 80%;
  height: 3px;
  background-color: #4caf50;
  border-radius: 3px 3px 0 0;
}

.category-icon {
  font-size: 1.2em;
}

.category-label {
  font-size: 0.9em;
  white-space: nowrap;
}

.block-selector {
  display: flex;
  overflow-x: auto;
  width: 100%;
  justify-content: flex-start;
  gap: 10px;
  padding: 15px;
  background-color: rgba(40, 44, 52, 0.95);
  border-radius: 0 0 8px 8px;
  max-height: 140px;
  overflow-y: hidden;
  flex-wrap: nowrap;
  align-items: center;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.3) transparent;
}

.block-selector::-webkit-scrollbar {
  height: 6px;
}

.block-selector::-webkit-scrollbar-track {
  background: transparent;
}

.block-selector::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
}

.block-selector::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.block-option-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;
  width: 60px;
  margin: 0 5px;
  flex-shrink: 0;
}

.block-option-container:hover {
  transform: translateY(-2px);
}

.block-option-container.selected {
  transform: scale(1.1);
}

.block-option {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-bottom: 5px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
}

.block-option.selected {
  border-color: #ffcc00;
}

.block-option.prop-option {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(60, 64, 72, 0.5);
}

.prop-emoji {
  font-size: 24px;
}

.block-label {
  font-size: 12px;
  color: #fff;
  text-align: center;
  max-width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.category-tab.close-build-mode {
  margin-left: auto;
  background-color: rgba(244, 67, 54, 0.1);
  color: #f44336;
}

.category-tab.close-build-mode:hover {
  background-color: rgba(0, 0, 0, 0.2);
  color: #ff5252;
}

.category-tab.close-build-mode .category-icon {
  font-weight: bold;
}

.category-tab.debug-mode-toggle {
  background-color: rgba(33, 150, 243, 0.1);
  color: #2196f3;
}

.category-tab.debug-mode-toggle.active {
  background-color: rgba(33, 150, 243, 0.3);
  color: #ffffff;
  border-color: #2196f3;
}

.category-tab.debug-mode-toggle:hover {
  background-color: rgba(33, 150, 243, 0.2);
  color: #64b5f6;
} 