.build-button {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  padding: 15px;
  width: 80px;
  height: 80px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  background-color: #455a64;
  color: white;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  z-index: 1001;
  transition: all 0.3s ease;
  pointer-events: auto;
}

.build-button.active {
  background-color: #4caf50;
  animation: pulse 2s infinite;
}

.build-icon {
  font-size: 32px;
}

.build-text {
  font-size: 12px;
  letter-spacing: 0.5px;
}

.build-button:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}

.build-button:active {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
  }
} 