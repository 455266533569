.item-menu {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  padding: 5px;
  min-width: 120px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  pointer-events: auto;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  flex-direction: column;
  gap: 5px;
  transform: translate(-50%, -50%); /* Center at cursor position */
}

.item-menu-header {
  padding: 5px 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 5px;
  text-align: center;
}

.item-menu-header h3 {
  margin: 0;
  padding: 0;
  color: white;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
}

.item-menu-button {
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
  width: 100%;
  text-align: left;
}

.item-menu-button:hover {
  background-color: #555;
}

.item-menu-icon {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-menu-label {
  flex-grow: 1;
}
