.ui-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* This allows clicks to pass through to the 3D scene */
  z-index: 10;
}

/* Make sure all clickable elements within have pointer events enabled */
.ui-container button,
.ui-container a,
.ui-container input,
.ui-container select,
.ui-container .block-selector-wrapper,
.ui-container .build-button,
.title-section,
.menu-toggle-button,
.menu-wrapper {
  pointer-events: auto;
}

.stats {
  display: none;
}

.stat {
  display: none;
}

.stat-label {
  display: none;
}

.stat-value {
  display: none;
}

.block-selector {
  display: flex;
  gap: 10px;
  padding: 10px;
  background-color: #2d2d2d;
  border-radius: 8px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.3) transparent;
  max-width: 80vw;
  margin: 0 auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

.block-selector::-webkit-scrollbar {
  height: 6px;
}

.block-selector::-webkit-scrollbar-track {
  background: transparent;
}

.block-selector::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
}

.category-tabs {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
  background-color: #2d2d2d;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

.category-tab {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  opacity: 0.7;
  transition: opacity 0.2s, background-color 0.2s;
}

.category-tab:hover {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.1);
}

.category-tab.selected {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.2);
}

.block-option-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  transition: background-color 0.2s;
  flex-shrink: 0;
  width: 60px;
}

.block-option-container:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.block-option-container.selected {
  background-color: rgba(255, 255, 255, 0.2);
}

.block-option {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.2);
}

.block-option.prop-option {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 4px;
}

.prop-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 2px;
}

.block-label {
  color: white;
  font-size: 12px;
  text-align: center;
  opacity: 0.8;
  pointer-events: none;
  user-select: none;
}

/* Title Section */
.title-section {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 100;
  background-color: #2d2d2d;
  padding: 12px 15px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

/* Game Title */
.game-title {
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  letter-spacing: 2px;
  pointer-events: none;
  user-select: none;
  margin-bottom: 5px;
}

/* Developer Credit */
.developer-credit {
  text-align: left;
  font-size: 12px;
  pointer-events: auto;
  opacity: 0.8;
}

.developer-credit a {
  color: #e0e0e0;
  text-decoration: none;
  transition: color 0.2s ease;
}

.developer-credit a:hover {
  color: #4dabf5;
}

.ui-instructions {
  margin-bottom: 5px;
  line-height: 1.2;
  font-size: 12px;
  text-align: center;
}

.block-count {
  margin-bottom: 3px;
  font-size: 12px;
  text-align: center;
}

.visible-blocks-count {
  margin-bottom: 6px;
  font-size: 12px;
  text-align: center;
  color: #ccffcc; /* Light green to distinguish from regular block count */
}

.reset-button {
  background: #ff5555;
  border: none;
  padding: 5px 8px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.2s;
  display: block;
  width: 100%;
  margin-bottom: 8px;
  pointer-events: auto;
}

.reset-button:hover {
  background: #ff7777;
}

.stress-test {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 6px;
}

.stress-test input {
  flex: 7;
  padding: 4px 5px;
  border-radius: 4px;
  border: none;
  font-size: 12px;
}

.stress-button {
  background: #5555ff;
  border: none;
  padding: 4px 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.2s;
  flex: 3;
}

.stress-button:hover {
  background: #7777ff;
}

.toggle-culling {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 0;
}

.toggle-culling input[type="checkbox"] {
  width: 13px;
  height: 13px;
}

/* Block counter overlay - completely separate from the main UI */
.block-counter-overlay {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: #2d2d2d;
  color: #e0e0e0;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 12px;
  pointer-events: none;
  user-select: none;
  z-index: 1000;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

.fps-counter {
  margin-top: 10px;
  font-size: 14px;
  color: #e0e0e0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

/* Block selector wrapper */
.block-selector-wrapper {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 10;
}

.category-icon {
  font-size: 16px;
}

.category-name {
  color: white;
  font-size: 14px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  user-select: none;
}

.block-option .tooltip {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 3px 6px;
  border-radius: 3px;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
  white-space: nowrap;
}

.block-option:hover .tooltip {
  opacity: 1;
}

.prop-emoji {
  font-size: 24px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  user-select: none;
}

/* Menu Tabs */
.menu-tabs {
  display: flex;
  gap: 5px;
  margin-bottom: 0;
  background-color: #2d2d2d;
  padding: 10px 10px 0 10px;
  border-radius: 8px 8px 0 0;
  pointer-events: auto;
}

.menu-tab {
  padding: 10px 15px;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 4px 4px 0 0;
  color: white;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
  flex: 1;
}

.menu-tab:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.menu-tab.active {
  background-color: rgba(255, 255, 255, 0.3);
  font-weight: bold;
}

/* Menu Content */
.menu-content {
  background-color: #2d2d2d;
  color: #e0e0e0;
  padding: 20px;
  border-radius: 0 0 8px 8px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  pointer-events: auto;
  max-height: 70vh;
  overflow-y: auto;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.menu-pane h3 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 18px;
  color: #ffffff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 8px;
  text-align: center;
}

.menu-item {
  margin-bottom: 16px;
}

.menu-button {
  background: #4dabf5;
  border: none;
  padding: 10px 15px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 500;
}

.menu-button:hover {
  background: #3b9de5;
}

.menu-button:disabled {
  background-color: #3a74a0;
  cursor: not-allowed;
  opacity: 0.7;
}

/* Settings Styles */
.setting-label {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
  cursor: pointer;
  font-weight: 500;
}

.setting-select {
  width: 100%;
  padding: 8px 12px;
  background-color: #3a3a3a;
  border: none;
  border-radius: 4px;
  color: #e0e0e0;
  margin-top: 8px;
  font-size: 14px;
}

.slider-container {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 8px;
}

.setting-slider {
  flex: 1;
  -webkit-appearance: none;
  height: 8px;
  background: #3a3a3a;
  border-radius: 4px;
  outline: none;
}

.setting-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  background: #4dabf5;
  border-radius: 50%;
  cursor: pointer;
}

.slider-value {
  min-width: 30px;
  text-align: center;
  font-weight: bold;
  background: rgba(255, 255, 255, 0.1);
  padding: 4px 8px;
  border-radius: 4px;
}

/* Debug Styles */
.debug-stat {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.debug-label {
  opacity: 0.8;
}

.debug-value {
  font-weight: bold;
  color: #90ee90; /* Light green */
}

/* Debug Warning Styles */
.debug-stat.warning .debug-label {
  color: #ff9900; /* Keep orange color for warning label */
}

.debug-stat.warning .debug-value {
  color: #ff6666; /* Keep light red for warning value */
  font-weight: bold;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Menu Toggle Button */
.menu-toggle-button {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  font-size: 20px;
  background-color: #2d2d2d;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  z-index: 1002;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

.menu-toggle-button:hover {
  background-color: #3b3b3b;
  transform: scale(1.05);
}

.menu-toggle-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
}

/* Menu Overlay */
.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Menu Wrapper */
.menu-wrapper {
  background-color: #2d2d2d;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  overflow: hidden;
  z-index: 1001;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

/* Mini Stats */
.mini-stats {
  position: fixed;
  top: 20px;
  right: 70px;
  background-color: #2d2d2d;
  color: #e0e0e0;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 14px;
  z-index: 100;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

.mini-stat {
  font-weight: bold;
}

.building-controls {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
  display: flex;
  align-items: flex-start;
  pointer-events: auto;
}

/* Loading Overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  flex-direction: column;
}

.loading-spinner {
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 5px solid #fff;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

.loading-text {
  color: white;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.progress-bar {
  width: 300px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar-fill {
  height: 100%;
  background-color: #4CAF50;
  border-radius: 5px;
  transition: width 0.3s ease;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Menu Close Button */
.menu-close-container {
  margin-top: 25px;
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 20px;
}

.menu-close-button {
  background-color: #5a5a5a;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  min-width: 150px;
}

.menu-close-button:hover {
  background-color: #6a6a6a;
} 