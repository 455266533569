.world-generation-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.world-generation-menu {
  background-color: #2d2d2d;
  border-radius: 8px;
  padding: 24px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  color: #e0e0e0;
}

.world-generation-menu h2 {
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
  color: #ffffff;
  font-size: 24px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.radio-options {
  display: flex;
  gap: 16px;
}

.radio-options label {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.slider-with-value {
  display: flex;
  align-items: center;
  gap: 12px;
}

.slider-with-value input[type="range"] {
  flex: 1;
}

.slider-value {
  min-width: 30px;
  text-align: center;
  font-weight: bold;
  background: rgba(255, 255, 255, 0.1);
  padding: 4px 8px;
  border-radius: 4px;
}

.slider-description {
  margin-top: 8px;
  font-size: 14px;
  text-align: center;
  opacity: 0.8;
  font-style: italic;
  color: #cccccc;
}

input[type="range"] {
  width: 100%;
  -webkit-appearance: none;
  height: 8px;
  background: #3a3a3a;
  border-radius: 4px;
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  background: #4dabf5;
  border-radius: 50%;
  cursor: pointer;
}

input[type="checkbox"] {
  margin-right: 8px;
}

select {
  width: 100%;
  padding: 8px 12px;
  background-color: #3a3a3a;
  color: #e0e0e0;
  border: none;
  border-radius: 4px;
  font-size: 16px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.cancel-button, .generate-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cancel-button {
  background-color: #5a5a5a;
  color: #ffffff;
}

.cancel-button:hover {
  background-color: #6a6a6a;
}

.generate-button {
  background-color: #4dabf5;
  color: #ffffff;
}

.generate-button:hover {
  background-color: #3b9de5;
}

.generate-button:disabled {
  background-color: #3a74a0;
  cursor: not-allowed;
  opacity: 0.7;
} 